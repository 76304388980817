.info-box {
  min-height: 15rem;
	border-radius: 10px;
  .content {
    .number {
      font-weight: 100;
      font-size: 60px;
      margin-top: -4px;
      color: #ffffff !important;
    }
    .text {
      color: #ffffff !important;
    }
  }
}
