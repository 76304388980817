.changed-logs {
  margin-bottom: 10px;

  // Flexbox
  display: flex;
  align-items: end;

  i {
    margin: 0 10px 0 10px;
    font-size: 20px;
  }

  .empty-old-value {
    font-style: italic;
    color: gainsboro;
  }
}