.header {
  border-bottom: 1px solid rgba(204,204,204,0.35);
  color: #555;
  padding: 20px;
  position: relative;

  h2 {
    color: $beepark;
    font-size: 16px;
    font-weight: bolder;
    margin: 0;
  }

  .col-xs-12 {
    h2 {
      margin-top: 5px;
    }
  }
}

.app-launcher-container {
  width: 100%;
  height: 100%;

  //Flexbox
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: start;

  a{
    text-decoration: none;
  }

  .app-card{
    @include box-shadow(0 5px 10px 0 rgba(0,0,0,0.3));
    @include border-radius(10px 10px 10px 10px);

    background-color: white;
    width: 200px;
    height: 200px;
    margin: 20px;
    padding: 10px;
    color: $beepark-black;

    border: .5px solid lightgray;

    // Flexbox
    display: flex;
    flex-direction: column;
    align-items: center;

    &.app-card:hover {
      background-color: lightgrey;
    }

    h5 {
      text-align: center;
      margin-bottom: 10px;
    }

    img {
      object-fit: scale-down;
      margin-bottom: 10px;
      padding: 10px;
      width: 150px;
      height: 150px;
    }
  }
}