.overlay {
	background-color: rgba(0,0,0,0.5);
	bottom: 0;
	cursor: pointer;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10;
}

.overlay-open {
	.sidebar {
		margin-left: 0;
		z-index: 99999999;
	}
}

.sidebar {
	background: #fdfdfd;
	height: calc(100vh - 70px);
	margin-top: 70px; // TopNab size
	overflow: hidden;
	width: 300px;

	.legal {
		border-top: 1px solid #eee;
		padding: 0 15px;
		height: 50px;
		width: 100%;

		.copyright {
			font-size: 13px;
			@include three-dots-overflow();

			a {
				font-weight: bold;
				text-decoration: none;
				line-height: 50px;
			}
		}

		.version {
			@include three-dots-overflow();
			font-size: 13px;
			margin-top: 5px;
		}
	}

	.user-info {
		background-color: $beepark !important;
		// background: url('../images/users-img-background.jpg') no-repeat no-repeat;
		height: 160px;
		padding: 13px 15px 12px 15px;
		position: relative;
		white-space: nowrap;


		.image {
			display: inline-block;
			margin-right: 12px;

			img {
				@include border-radius(50%);
				vertical-align: bottom !important;
			}
		}

		.info-container {
			cursor: default;
			display: block;
			position: relative;
			top: 25px;

			.name {
				@include three-dots-overflow();
				color: #fff;
				font-size: 16px;
				max-width: 200px;
				letter-spacing: 1px;
				font-weight: bolder;
			}

			.email {
				@include three-dots-overflow();
				color: lighten($beepark, 50%);
				font-size: 11px;
				max-width: 200px;
				// font-style: italic;
				letter-spacing: 1px;
			}

			.user-helper-dropdown {
				bottom: -12px;
				color: #fff;
				cursor: pointer;
				position: absolute;
				right: -3px;
				@include box-shadow(none);
				a {
					color: #fff;
					cursor: pointer;
				}
				.dropdown-menu > li > a {
					color: #666;
				}
			}
		}
	}

	.pending-user-actions {
		background-color: map-get($colors, red);
		color: white;
		font-size: 12px;
		min-height: 75px;
		max-height: 150px;
		overflow-y: auto;

		.action {
			height: 75px;

			i {
				margin-right: 10px;
			}

			padding: 15px;

			border-bottom: 2.5px solid #ab2a3e;

			// Flexbox
			display: flex;
			flex-flow: row nowrap;
			align-items: stretch;
		}

		.action:hover {
			cursor: pointer;
			background-color: tomato;
		}
	}

	.sidebar-body {
		height: calc(100vh - 70px - 160px);
		overflow: hidden;

		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: stretch;
	}

	.menu {
		border-top: 6px solid $beepark-yellow;
		overflow-y: auto;
		position: relative;
		flex-grow: 1;

		.list {
			list-style: none;
			padding-left: 0;

			li {
				&.active {
					color: $beepark;
					> :first-child {
						span {
							font-weight: bold;
						}
					}
				}
			}

			.header {
				background: #eee;
				font-size: 12px;
				font-weight: 600;
				padding: 8px 16px;
			}

			i.material-icons {
				margin-top: 4px;
			}

			.menu-toggle {
				&:after, &:before {
					font-size: 24px;
					position: absolute;
					right: 17px;
					top: calc(50% - 17px);
					@include transform(scale(0));
					@include transition(all .3s);
				}

				&:before {
					content: 'keyboard_arrow_down';
					// content: 'add';
					font-family: 'Material Icons';
					@include transform(scale(1));
				}

				&:after {
					content: 'keyboard_arrow_up';
					// content: 'minimize';
					font-family: 'Material Icons';
					// top:8px;
					// content: '\2013';
					@include transform(scale(0));
				}
			}

			.menu-toggle.toggled {
				&:before {
					@include transform(scale(0));
				}

				&:after {
					@include transform(scale(1));
				}
			}

			a {
				-webkit-transition-duration: .12s;
				// color: #747474;
				color: $beepark-darkgray;
				display: -webkit-inline-box;
				display: -ms-inline-flexbox;
				display: inline-flex;
				padding: 10px 13px;
				position: relative;
				transition-duration: .12s;
				vertical-align: middle;
				width: 100%;

				&:hover,
				&:active,
				&:focus {
					background-color: rgba($beepark,.12);
					font-weight: bolder;
					text-decoration: none !important;
				}

				small {
					position: absolute;
					right: 15px;
					top: calc(50% - 7.5px);
				}

				span {
					color: #333;
					font-size: 14px;
					font-weight: bold;
					margin: 7px 0 7px 12px;
					overflow: hidden;
				}
			}

			.ml-menu {
				display: none;
				list-style: none;
				padding-left: 0;

				span {
					font-size: 14px;
					font-weight: normal;
					margin: 3px 0 1px 6px;
				}

				li {
					a {
						border-bottom: 1px solid rgba($beepark-gray,.1);
						padding-bottom: 10px;
						padding-left: 60px;
						padding-top: 10px;
					}

					&.active {
						a.toggled:not(.menu-toggle) {
							font-weight: 600;
							margin-left: 5px;

							&:before {
								content: '\E315';
								font-family: 'Material Icons';
								font-size: 21px;
								height: 20px;
								position: relative;
								right: 0px;
								top: -5px;
							}
						}
					}

					.ml-menu {
						li {
							a {
								padding-left: 80px;
							}
						}

						.ml-menu {
							li {
								a {
									padding-left: 95px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.right-sidebar {
	background: #fdfdfd;
	height: calc(100vh - 70px);
	overflow: hidden;
	position: fixed;
	right: -300px;
	top: 70px;
	width: 280px;
	z-index: 11 !important;
	@include box-shadow(-2px 2px 5px rgba(0, 0, 0, 0.1));
	@include transition(.5s);

	&.open {
		right: 0;
	}

	.nav-tabs {
		font-size: 13px;
		font-weight: 600;
		margin-left: 2px;
		width: 100%;

		li {
			text-align: center;

			> a {
				margin-right: 0;
			}

			&:first-child {
				width: 45%;
			}

			&:last-child {
				width: 55%;
			}
		}
	}
}
