.btn {
	border-radius: 6px !important;
	&:focus {
		outline: none !important;
	}
}

.btn-circle {
	border: none;
	height: 40px;
	outline: none !important;
	overflow: hidden;
	width: 40px;
	@include border-radius(50%);

	i {
		font-size: 18px;
		left: -1px;
		position: relative;
	}
}

.btn-link {
	color: #333;
	font-weight: bold;
	@include transition(.5s);

	&:active,
	&:focus {
		color: #333;
		text-decoration: none;
	}

	&:hover {
		background-color: #ddd;
		color: #333;
		text-decoration: none;
	}
}

.btn-circle-lg {
	border: none;
	height: 50px;
	outline: none !important;
	overflow: hidden;
	width: 50px;
	@include border-radius(50% !important);

	i {
		font-size: 26px !important;
		left: 0px !important;
		position: relative !important;
		top: 6px !important;
	}
}

.btn:not(.btn-link):not(.btn-circle) {
	-webkit-box-shadow: 0 2px 5px rgba(0,0,0,.16),0 2px 10px rgba(0,0,0,.12);
	box-shadow: 0 2px 5px rgba(0,0,0,.16),0 2px 10px rgba(0,0,0,.12);
	@include border-radius(2px);
	border: none;
	font-size: 13px;
	outline: none;

	&:hover {
		outline: none;
	}

	i {
		font-size: 20px;
		position: relative;
		top: 3px;
	}

	span {
		margin-left: 3px;
		position: relative;
		top: -2px;
	}
}

.btn-warning,
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
	background-color: $warning !important;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
	background-color: $danger !important;
}

.btn-info,
.btn-info:hover,
.btn-info:active,
.btn-info:focus {
	background-color: $info !important;
}

.btn-success,
.btn-success:hover,
.btn-success:active,
.btn-success:focus {
	background-color: $success !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
	background-color: $beepark !important;
}

.btn-default,
.btn-default:hover,
.btn-default:active,
.btn-default:focus {
	background-color: $beepark-gray !important;
	color: map-get($colors, white)
}

.btn-group,
.btn-group-vertical {
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);

	.btn {
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		@include border-radius(0);

		.caret {
			bottom: 1px;
			position: relative;
		}
	}

	.btn-group {
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}

	.btn + .dropdown-toggle {
		border-left: 1px solid rgba(0,0,0,0.08) !important;
	}
}
