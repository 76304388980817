.display-block {
  display: block;
}

.row-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.checkbox-label {
  color: $beepark-darkgray !important;
}

.row-checkbox .row-input-checkbox {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 2px solid $beepark-darkgray;
  cursor: pointer;

  margin-top: -8px !important;
}

.disable-cursor {
  cursor: auto !important;
}

.row-checkbox input:checked ~ .row-input-checkbox {
  background-color: $beepark;
  border-radius: 0;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid $beepark;

  margin-top: -8px !important;
}

input:disabled:checked ~ .row-input-checkbox {
  background-color: $beepark-gray;
  border: 2px solid $beepark-gray;

  margin-top: -8px !important;
}

.row-checkbox .row-input-checkbox::after {
  position: absolute;
  content: "";
  left: 0;
  top: 6px;
  height: 0;
  width: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  opacity: 1;

  margin-top: -8px !important;
}

.row-checkbox input:checked ~ .row-input-checkbox::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 4px;
  top: 0;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;

  margin-top: 0px !important;
}
