.navbar {
  font-family: $navbar-font-family;
  @include border-radius(0);
  @include box-shadow(0 1px 5px rgba(0, 0, 0, .3));
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  background-color: #fff !important;

  .navbar-brand {
    img {
      height: 100%;
    }

    @include three-dots-overflow();
  }

  .navbar-custom-right-menu {
    float: right;
  }

  .navbar-toggle {
    text-decoration: none;
    color: #fff;
    width: 20px;
    height: 20px;
    margin-top: -4px;
    margin-right: 17px;

    &:before {
      content: '\E8D5';
      font-family: 'Material Icons';
      font-size: 26px;
    }
  }

  .navbar-collapse {
    &.in {
      overflow: visible;
    }
  }

  .title-alert {
    color: $beepark;
  }

  .menuitem-body {
    height: auto;
    max-height: 400px;
    width: 300px;
    overflow-x: hidden;
  }

  .menuitem-item {
    white-space: normal;
    color: #555;
    line-height: inherit;
  }

  .time-alert {
    color: rgba($beepark-darkgray, .8);
    font-size: 1.1rem;
    letter-spacing: .05rem;
    float: right;
    i {
      font-size: 1.2rem !important;
      //margin-right: 1rem;
    }
  }
}

.sidebar-closed {
  .sidebar {
    margin-left: -300px;
  }

  section.content {
    margin-left: 15px;
  }

  .bars {
    &:after,
    &:before {
      color: $beepark;
      font-family: 'Material Icons';
      font-size: 24px;
      position: absolute;
      top: 18px;
      left: 20px;
      margin-right: 10px;
      @include transform(scale(0));
      @include transition(all .3s);
    }

    &:before {
      content: '\E5D2';
      @include transform(scale(1));
    }

    &:after {
      content: '\E5C4';
      @include transform(scale(0));
    }
  }

  .navbar-brand {
    margin-left: 30px;
  }
}

.overlay-open {
  .bars {
    &:before {
      @include transform(scale(0));
    }

    &:after {
      @include transform(scale(1));
    }
  }
}

.navbar-header {
  padding: 10px 7px;

  .bars {
    float: left;
    text-decoration: none;
  }
}

.navbar-nav {
  > li {
    > a {
      padding: 7px 7px 2px 7px;
      margin-top: 17px;
      margin-left: 5px;

      i {
        color: $beepark;
      }
    }
  }

  .dropdown-menu {
    margin-top: -40px !important;
  }
}

.label-count {
  position: absolute;
  top: -5px;
  right: -6px;
  font-size: 12px;
  font-weight: bolder;
  line-height: 15px;
  background-color: $danger;
  padding: 1px 6px;
  border: 3px solid white;
  @include border-radius(20px);
}

@each $key, $val in $colors {
  .col-#{$key} {
    .navbar {
      @include navbar-link-color(#fff, #000, .95);
      // @include navbar-link-color(rgba(0,0,0,0.85), #000, .95);
    }
  }
}

.navbar-brand {
  padding: 4px 10px;
}

.notification-unlinked:hover {
  cursor: no-drop;
}
