.table {
	tbody {
		tr {
			td, th {
				padding: 10px;
				border-top: 1px solid #eee;
				border-bottom: 1px solid #eee;
			}
		}

		tr.primary {
			td, th {
				background-color: #1f91f3;
				color: #fff;
			}
		}

		tr.success {
			td, th {
				background-color: $success;
				color: #fff;
			}
		}

		tr.info {
			td, th {
				background-color: #00b0e4;
				color: #fff;
			}
		}

		tr.warning {
			td, th {
				background-color: $warning;
				color: #fff;
			}
		}

		tr.danger {
			td, th {
				background-color: $danger;
				color: #fff;
			}
		}
	}

	thead {
		tr {
			th {
				padding: 10px;
				border-bottom: 1px solid #eee;
			}
		}
	}
}

.table-bordered {
	border-top: 1px solid #eee;

	tbody {
		tr {
			td, th {
				padding: 10px;
				border: 1px solid #eee;
			}
		}
	}

	thead {
		tr {
			th {
				padding: 10px;
				border: 1px solid #eee;
			}
		}
	}
}

th {
	color:#aaa;
	:hover {
		text-decoration: none;
	}
}

th.active, th.active > a {
	color: $beepark;
	background-color: transparent !important;
}

.table tbody > tr {
	-webkit-transition-duration: .15s;
	transition-duration: .15s;
	color: $beepark-darkgray;
	&:hover {
		background-color: rgba($beepark, .12);
		// color: #fff;
	}
}

.table tbody > tr > td{
	vertical-align: middle;
	a {
		text-decoration: none;
	}
}

table thead th i.material-icons, table tfoot th i.material-icons {
	font-size: 12px;
	margin-left: 5px;
	color: $beepark;
}

.table-icon-action {
	.material-icons {
		font-size: 20px;
		vertical-align: middle;
	}
}

.table-error {
	text-align: center;
	font-size: 2.4rem;
	color: $beepark-gray;
}

.tr-cursor {
	cursor: pointer;
	&:hover {
		color: $beepark;
	}
}

.td-checkbox {
	margin: 0px !important;
	padding: 0px !important;
	width: 4rem;
}

.tooltip-table {
	background-color:  #fff;
	border-radius: 25px;
}