.pendiente, .valido, .rechazado, .caducado, .up-to-date, .active-state, .inactive-state {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top:1px;
  margin-right: 4px;
  margin-left: 4px;

}
.bg-orange {
  background-color: $warning !important;
}

.pendiente {
  background-color: $warning;
}
.valido {
  background-color: $success;
}
.rechazado {
  background-color: $danger;
}
.caducado,.bg-gray {
  background-color: $beepark-darkgray;
}
.up-to-date {
  background-color: $success;
}
.active-state {
  background-color: $warning;
}
.inactive-state {
  background-color: $success;
}
