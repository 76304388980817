// Pavasal Colors
$success: #8bc34a;
$warning: #ffcc00;
$danger: tomato;
$info: #607D8B;

$beepark: #0063a2;
$beepark-yellow: #ffcc00;
$beepark-gray: #607D8B;
$beepark-darkgray: #515961;
$beepark-black: #222;
$beepark-background: lighten($beepark-darkgray, 58%);

//Colors
$colors: (
  red: #F44336,
  pink: #E91E63,
  purple: #9C27B0,
  deep-purple: #673AB7,
  indigo: #3F51B5,
  blue: #2196F3,
  light-blue: #03A9F4,
  cyan: #00BCD4,
  teal: #009688,
  green: #4CAF50,
  light-green: #8BC34A,
  lime: #CDDC39,
  yellow: #ffe821,
  amber: #FFC107,
  orange: #FF9800,
  deep-orange: #FF5722,
  brown: #795548,
  grey: #9E9E9E,
  blue-grey: #607D8B,
  black: #000000,
  white: #ffffff
) !default;

//Fonts Family
$navbar-font-family: 'Raleway','Roboto', sans-serif;
$sidebar-font-family: 'Raleway','Roboto', sans-serif;

//Bootstrap Datepicker
$datepicker-active-color: #009688;

// CheckBox and Radio Buttons

//== Media queries breakpoints
// fuente: https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
