﻿.alert {
    @include border-radius(0);
    @include box-shadow(none);
    border: none;
    color: #fff !important;

    .alert-link {
        color: #fff;
        text-decoration: underline;
        font-weight: bold;
    }
}

.alert-success {
    background-color: $success;
}

.alert-info {
    background-color: #00b0e4;
}

.alert-warning {
    background-color: $warning !important;
}

.alert-danger {
    background-color: $danger !important;
}

.alert-light {
    color: #999 !important;
    background-color: #fff;
}

.alert-dismissible {
    .close {
        color: #fff;
        opacity: 1;
        border: none;
        text-shadow: none;
    }
}

