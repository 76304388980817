﻿.media {
    margin-bottom: 25px;

    .media-body {
        color: #777;
        font-size: 13px;

        .media-heading {
            font-size: 16px;
            font-weight: bold;
            color: #333;
        }
    }
}
