.dropdown-menu {
	@include border-radius(0);
	-webkit-box-shadow: 0 2px 14px rgba($beepark-black, .2);
	border: none;
	border-radius: 10px;
	box-shadow: 0 2px 14px rgba($beepark-black, .2);
	margin-top: -35px !important;

	.divider {
		margin: 5px 0;
	}

	.header {
		border-bottom: 1px solid #eee;
		font-size: 13px;
		font-weight: bold;
		min-width: 270px;
		padding: 4px 0 6px 0;
		text-align: center;
	}

	ul.menu {
		padding-left: 0;

		&.tasks {
			h4 {
				color: #333;
				font-size: 13px;
				margin: 0 0 8px 0;

				small {
					float: right;
					margin-top: 6px;
				}
			}

			.progress {
				height: 7px;
				margin-bottom: 7px;
			}
		}

		.icon-circle {
			color: #fff;
			display: inline-block;
			height: 36px;
			text-align: center;
			width: 36px;
			@include border-radius(50%);

			i {
				font-size: 18px;
				line-height: 36px;
			}
		}

		li {
			border-bottom: 1px solid #eee;

			&:last-child {
				border-bottom: none;
			}

			a {
				padding: 7px 11px;
				text-decoration: none;
				@include transition(.5s);

				&:hover {
					background-color: rgba($beepark,.12);
				}
			}
		}

		.menu-info {
			display: inline-block;
			left: 5px;
			position: relative;
			top: 3px;

			h4 {
				color: #333;
				font-size: 13px;
				margin: 0;
			}

			p {
				color: #aaa;
				font-size: 11px;
				margin: 0;

				.material-icons {
					color: #aaa;
					font-size: 13px;
					position: relative;
					top: 2px;
				}
			}
		}
	}

	.footer {
		a {
			border-top: 1px solid #eee;
			font-size: 12px;
			margin-bottom: -5px;
			padding: 5px 0 5px 0;
			text-align: center;

			&:hover {
				background-color: transparent;
			}
		}
	}

	> li {
		> a {
			border-radius: 7px;
			color: $beepark-gray;
			font-size: 14px;
			line-height: 25px;
			padding: 7px 18px;
			@include transition(all .15s);
			margin-left: 5px;
			margin-right: 5px;

			&:hover {
				background-color: rgba($beepark,.12);
				color: $beepark;
			}

			i.material-icons {
				float: left;
				font-size: 20px;
				margin-right: 7px;
				margin-top: 2px;
			}
		}
	}
}

.dropdown-animated {
	-webkit-animation-duration: .3s !important;
	animation-duration: .3s !important;
}
