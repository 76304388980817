﻿.panel-group {
    @each $key, $val in $colors {
        .panel-col-#{$key} {
            border: 1px solid $val;

            .panel-title {
                background-color: $val !important;
                color: #fff;
            }

            .panel-body {
                border-top-color: transparent !important;
            }
        }
    }

    .panel {
        @include border-radius(0);

        .panel-title {
            .material-icons {
                float: left;
                line-height: 16px;
                margin-right: 8px;
            }
        }

        .panel-heading {
            padding: 0;
            @include border-radius(0);

            a {
                display: block;
                padding: 10px 15px;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }
        }

        .panel-body {
            color: #555;
        }
    }

    .panel-primary {
        border: 1px solid #1f91f3;

        .panel-title {
            background-color: #1f91f3;
        }
    }

    .panel-success {
        border: 1px solid $success;

        .panel-title {
            background-color: $success;
            color: #fff;
        }
    }

    .panel-warning {
        border: 1px solid $warning;

        .panel-title {
            background-color: $warning;
            color: #fff;
        }
    }

    .panel-danger {
        border: 1px solid $danger;

        .panel-title {
            background-color: $danger;
            color: #fff;
        }
    }
}

.full-body {
    @each $key, $val in $colors {
        .panel-col-#{$key} {
            .panel-body {
                border-top-color: #fff !important;
                background-color: $val;
                color: #fff;
            }
        }
    }

    .panel-primary {
        .panel-body {
            border-top-color: #fff !important;
            background-color: #1f91f3;
            color: #fff;
        }
    }

    .panel-success {
        .panel-body {
            border-top-color: #fff !important;
            background-color: $success;
            color: #fff;
        }
    }

    .panel-warning {
        .panel-body {
            border-top-color: #fff !important;
            background-color: $warning;
            color: #fff;
        }
    }

    .panel-danger {
        .panel-body {
            border-top-color: #fff !important;
            background-color: $danger;
            color: #fff;
        }
    }
}
