﻿.ms-container {
    width: auto !important;

    .ms-list {
        @include box-shadow(none !important);
        @include border-radius(0 !important);
    }

    .ms-list.ms-focus {
        @include box-shadow(none !important);
    }

    .ms-selectable,
    .ms-selection {
        min-width: 250px !important;

        li.ms-hover {
            color: #000000 !important;
            background-color: #e6e6e6 !important;
        }

        li.ms-elem-selectable,
        li.ms-elem-selection {
            padding: 9px 15px 6px 15px !important;
        }
    }

    .ms-optgroup-label {
        padding: 5px 0 0 8px !important;
    }
}

.multi-select {
    margin-top: 7px;

    input {
        width: 100%;
        padding-right: 26px;
        margin-bottom: 5px;
        border: none;
        margin-top: 5px;
        background-color: transparent;
    }

    i {
        position: absolute;
        right: 0;
        margin-top: -2px;
        font-size: 26px;
        margin-left: 5px;
        color: #000;
    }

    .multi-select-options {
        position: absolute;
        background-color: white;
        border-color: #cccccc;
        border-width: 1px;
        border-style: solid;
        width: 100%;
        z-index: 1000;
        padding: 0;
        max-height: 300px;
        overflow-y: auto;
        //border-bottom-left-radius: 8px;
        //border-bottom-right-radius: 8px;


        .multi-select-option {
            display: block;
            width: 100%;
            padding: 5px 10px 5px 10px;
        }
        .multi-select-option:hover {
            background-color: rgba(0, 99, 162, 1);
            color: white;
            cursor: pointer;
        }
        .multi-select-option[data-active="true"] {
            background-color: rgba(0, 99, 162, 0.2);
        }
        .multi-select-option[data-active="true"]:hover {
            background-color: rgba(0, 99, 162, 1);
        }

    }

}
