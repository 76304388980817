.login-page {
	-ms-flex-direction: column;
	-ms-flex-pack: center;
	-webkit-box-direction: normal;
	-webkit-box-orient: vertical;
	-webkit-box-pack: center;
	background-color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	max-width: 360px;
	min-height: 100vh;
	overflow-x: hidden;
	padding-left: 0;

	.card {
		background-color: lighten($beepark-darkgray,55%);
		padding: 3rem 1rem;

	}

	.login-box {
		.msg {
			color: $beepark-darkgray;
			margin-bottom: 30px;
			text-align: center;
		}

		a {
			color: $beepark;
			font-size: 12px;
			font-weight: bold;
			text-decoration: none;
			&:hover {
				color: lighten($beepark,10%);
			}
		}

		.logo {

			margin-bottom: 20px;
			max-width: 80vw;
			margin-left: auto;
			margin-right: auto;
			a {
				color: #fff;
				display: block;
				font-size: 36px;
				text-align: center;
				width: 100%;
			}

			small {
				color: $beepark;
				display: block;
				margin-top: -5px;
				text-align: center;
				width: 100%;
				display: none;
			}
		}

		.btn {
			background-color: $beepark !important;
		}


	}
}

.error-message {
	color: $danger;
	font-weight: 400;
	font-style: italic;
}