﻿/* Google Maps */
.gmap {
    width: 100%;
    height: 430px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 30px;
}

/* jVector Map */
.jvector-map {
    width: 100%;
    height: 600px;
}
